import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';

import { environment } from '../../../environments/environment';

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap: Map<string, string[]> = new Map();
  protectedResourceMap.set(environment.backendConfig.baseUrl, environment.msalConfig.apiConfig.scopes);

  const unprotectedResourceMap: Map<string, null> = new Map();
  unprotectedResourceMap.set(environment.backendConfig.baseUrl + '/registration/start', null);
  unprotectedResourceMap.set(environment.backendConfig.baseUrl + '/registrationIntents', null);
  unprotectedResourceMap.set(environment.backendConfig.baseUrl + '/products', null);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([...unprotectedResourceMap, ...protectedResourceMap]),
  };
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.msalConfig.apiConfig.scopes],
    },
    loginFailedRoute: environment.msalConfig.auth.loginFailedRoute,
  };
}
