import { Component } from '@angular/core';
import { CourseInstancesOverviewComponent } from '../../../domains/courses/components/course-instances-overview/course-instances-overview.component';
import { TranslocoModule } from '@jsverse/transloco';
import { easeInAnimationFast } from '../../../shared/animations/ease-in-animation';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [CourseInstancesOverviewComponent, TranslocoModule],
  animations: [easeInAnimationFast],
  templateUrl: './overview-container.component.html',
  styleUrl: './overview-container.component.css',
})
export class OverviewContainerComponent {}
