import { Component, EventEmitter, Output } from '@angular/core';
import { PrimaryButtonComponent } from '../../../../shared/components/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../../../../shared/components/secondary-button/secondary-button.component';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonRowComponent } from '../../../../shared/components/button-row/button-row.component';
import { UserService } from '../../services/user.service';
import { emailValidationPattern, forbiddenCharactersValidator } from '../../../../shared/validators/custom-validators';
import { MatDividerModule } from '@angular/material/divider';
import { TextInputComponent } from '../../../../shared/components/text-input/text-input.component';
import { transformUserFormDataToUserDTO } from '../../mappers/dto-mappers';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

export interface UserFormControls {
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
}

@Component({
  selector: 'app-user-creation-container',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TextInputComponent,
    TranslocoDirective,
    ButtonRowComponent,
    MatDividerModule,
    SecondaryButtonComponent,
  ],
  templateUrl: './user-creation-container.component.html',
  styleUrl: './user-creation-container.component.css',
})
export class UserCreationContainerComponent {
  @Output() backButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() userSuccessfullyCreatedEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() csvUploadButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();

  isLoading = false;

  userCreationForm: FormGroup<UserFormControls> = this.formBuilder.group<UserFormControls>({
    firstName: this.formBuilder.nonNullable.control({ value: '', disabled: false }, [
      Validators.required,
      Validators.maxLength(50),
      forbiddenCharactersValidator(),
    ]),
    lastName: this.formBuilder.nonNullable.control({ value: '', disabled: false }, [
      Validators.required,
      Validators.maxLength(50),
      forbiddenCharactersValidator(),
    ]),
    email: this.formBuilder.nonNullable.control({ value: '', disabled: false }, [
      Validators.required,
      Validators.pattern(emailValidationPattern),
      forbiddenCharactersValidator(),
    ]),
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly snackbarService: SnackbarService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  handleUserCreationSubmit() {
    this.createUser();
  }

  private createUser() {
    this.isLoading = true;
    this.userCreationForm.disable();
    const userToCreate = transformUserFormDataToUserDTO(this.userCreationForm.value);
    this.userService.createUsers([userToCreate]).subscribe({
      next: () => {
        this.isLoading = false;
        this.analyticsService.track('user_created');
        this.userSuccessfullyCreatedEvent.emit();
      },
      error: (error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        this.isLoading = false;
        this.userCreationForm.reset();
        this.userCreationForm.enable();
      },
    });
  }

  handleBackButtonClick() {
    this.backButtonClickEvent.emit();
  }

  handleCsvButtonClick() {
    this.analyticsService.track('csv_upload_clicked');
    this.csvUploadButtonClickEvent.emit();
  }
}
