import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { PrimaryButtonComponent } from '../../../../shared/components/primary-button/primary-button.component';
import { SecondaryButtonComponent } from '../../../../shared/components/secondary-button/secondary-button.component';
import { CourseEnrollmentTableComponent } from '../../../../domains/courses/components/course-enrollment-table/course-enrollment-table.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { ButtonRowComponent } from '../../../../shared/components/button-row/button-row.component';
import { CourseDataService } from '../../../../domains/courses/services/course-data.service';
import { CourseService } from '../../../../domains/courses/services/course.service';
import { catchError, EMPTY, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { UserService } from '../../../../domains/user/services/user.service';
import { UserUIModel } from '../../../../domains/user/models/UserUIModel';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { SepTableSelectionService } from '../../../../shared/components/sep-table/services/sep-table-selection.service';
import { CourseEnrollmentNotificationService } from '../../../../domains/courses/services/course-enrollment-notification.service';
import { SnackbarService } from '../../../../core/services/snackbar.service';
import { CommonModule } from '@angular/common';
import { Select } from '@ngxs/store';
import { CurrentUserState } from '../../../../core/store/current-user/current-user.state';
import { LicenseInfoCardComponent } from '../../../../domains/subscription/components/license-info-card/license-info-card.component';
import { InfoCardComponent } from '../../../../shared/components/info-card/info-card.component';
import { easeInAnimation } from '../../../../shared/animations/ease-in-animation';
import { LanguageService } from '../../../../core/services/language.service';
import { UserResponseDTO } from '../../../../domains/user/models/UserDTO';
import { StudentEligibleForEnrollmentUIModel } from '../../../../domains/courses/models/StudentUIModel';
import { SubscriptionService } from '../../../../domains/subscription/services/subscription.service';
import { environment } from '../../../../../environments/environment';
import {
  LicenseStatus,
  SubscriptionLicensesResponseDTO,
} from '../../../../domains/subscription/models/SubscriptionDTO';
import { CustomPortalError } from '../../../../core/errors/models/CustomPortalError';
import { TextWithLinkComponent } from '../../../../shared/components/text-with-link/text-with-link.component';
import { AnalyticsService } from '../../../../core/services/analytics/analytics.service';

@Component({
  selector: 'app-course-enrollment-container',
  standalone: true,
  imports: [
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    CourseEnrollmentTableComponent,
    CommonModule,
    TranslocoDirective,
    ButtonRowComponent,
    LoadingComponent,
    LicenseInfoCardComponent,
    InfoCardComponent,
    TextWithLinkComponent,
  ],
  providers: [SepTableSelectionService],
  animations: [easeInAnimation],
  templateUrl: './course-enrollment-container.component.html',
  styleUrl: './course-enrollment-container.component.css',
})
export class CourseEnrollmentContainerComponent implements OnInit, OnDestroy {
  @Output() userCreationButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() abortButtonClickEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() studentSuccessfullyEnrolledEvent: EventEmitter<void> = new EventEmitter<void>();
  @Select(CurrentUserState.numberOfLicences) numberOfLicenses$: Observable<number>;

  studentsEligibleForEnrollment: StudentEligibleForEnrollmentUIModel[] | undefined = undefined;
  selectedStudents: StudentEligibleForEnrollmentUIModel[];
  availableLicenses$: Observable<number>;
  courseId: string | undefined;
  isLoading = false;
  canEnroll = false;
  supportEmail = environment.general.hvsSupportContactEmail;

  private readonly destroy$: Subject<void> = new Subject();

  constructor(
    private readonly courseDataService: CourseDataService,
    private readonly courseService: CourseService,
    private readonly userService: UserService,
    private readonly tableSelectionService: SepTableSelectionService<UserUIModel>,
    private readonly courseEnrollmentNotificationService: CourseEnrollmentNotificationService,
    private readonly snackbarService: SnackbarService,
    private readonly languageService: LanguageService,
    private readonly subscriptionService: SubscriptionService,
    private readonly analyticsService: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.courseId = this.courseDataService.getCourseId();
    this.getStudentsEligibleForEnrollment();
    this.getAvailableLicenses();
    this.tableSelectionService.selection$.pipe(takeUntil(this.destroy$)).subscribe((selection) => {
      this.selectedStudents = selection.selected;
      this.checkLicenseEligibilityForEnrollment();
    });
  }

  handleUserCreationButtonClick(): void {
    this.analyticsService.track('create_employees_clicked');
    this.userCreationButtonClickEvent.emit();
  }

  handleAbortButtonClick(): void {
    this.abortButtonClickEvent.emit();
  }

  private getAvailableLicenses() {
    this.availableLicenses$ = this.subscriptionService.getLicenses().pipe(
      takeUntil(this.destroy$),
      map(
        (response: SubscriptionLicensesResponseDTO) =>
          response.licenses.filter((license) => license.status === LicenseStatus.AVAILABLE).length,
      ),
    );
  }

  /* future note: can be outsourced to a service when we need the functionality in more places*/
  private getStudentsEligibleForEnrollment() {
    if (this.courseId) {
      this.courseService
        .getCourseDetail(this.courseId)
        .pipe(
          switchMap((courseDetail) => {
            /*we compare enrolled students to all users in the organization.
            The diff reflects students that are eligible to be enrolled in the course.*/
            const enrolledStudents = courseDetail.courseInstance.students;
            return this.userService.getUsers().pipe(
              map((responseDTO) => {
                const usersEligibleForEnrollment = responseDTO.users.filter(
                  (user) => !enrolledStudents.some((enrolledStudent) => enrolledStudent.id === user.id),
                );
                this.studentsEligibleForEnrollment = this.transformUsersToStudents(usersEligibleForEnrollment);
              }),
            );
          }),
          catchError((error: CustomPortalError) => {
            this.snackbarService.error(error.errorType);
            return EMPTY;
          }),
        )
        .subscribe();
    }
  }

  private transformUsersToStudents(users: UserResponseDTO[]): StudentEligibleForEnrollmentUIModel[] {
    return users.map((user) => {
      const username = `${user.firstName} ${user.lastName}`;
      const { email, id, licenses } = user;
      return {
        id,
        username,
        email,
        license: licenses.length ? licenses[0] : null,
      };
    });
  }

  private checkLicenseEligibilityForEnrollment(): void {
    this.availableLicenses$
      .pipe(
        takeUntil(this.destroy$),
        map((availableLicenses) => availableLicenses - this.countSelectedStudentsWithoutLicenseAssigned()),
        map((licenseDiff) => licenseDiff >= 0),
      )
      .subscribe((canEnroll) => {
        this.canEnroll = canEnroll;
      });
  }

  private countSelectedStudentsWithoutLicenseAssigned(): number {
    return this.selectedStudents.reduce((count, student) => {
      if (!student.license?.status) {
        count += 1;
      }
      return count;
    }, 0);
  }

  enrollStudents() {
    this.isLoading = true;
    const { courseId } = this;
    const students = this.selectedStudents.map((student) => ({ id: student.id }));
    if (students.length && courseId) {
      this.courseService.enrollStudentsToCourse(students, courseId).subscribe({
        next: () => {
          this.isLoading = false;
          this.courseEnrollmentNotificationService.setEnrollmentChanged();
          this.analyticsService.track('students_enrolled', { courseId: courseId, students: students });
          this.studentSuccessfullyEnrolledEvent.emit();
        },
        error: (error: CustomPortalError) => {
          this.isLoading = false;
          this.snackbarService.error(error.errorType);
        },
      });
    }
  }

  getEnrollmentDescription() {
    const numberOfLicenses = this.countSelectedStudentsWithoutLicenseAssigned();
    if (numberOfLicenses === 0) {
      return this.languageService.translateOnRuntime('portal.courses.enrollment.licenses.assignmentExplanationZero');
    } else if (numberOfLicenses === 1) {
      return this.languageService.translateOnRuntime(
        'portal.courses.enrollment.licenses.assignmentExplanationSingular',
      );
    } else {
      return this.languageService.translateOnRuntime('portal.courses.enrollment.licenses.assignmentExplanationPlural', {
        numberOfLicenses,
      });
    }
  }

  @HostListener('window:keydown.enter', ['$event'])
  onEnterKey() {
    if (this.selectedStudents.length > 0 && this.canEnroll) {
      this.enrollStudents();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
