<div class="rounded-lg border border-grey-lightGrey shadow-sm">
  <div class="max-h-40 overflow-y-auto rounded-lg">
    <table mat-table [dataSource]="dataView" class="w-full">
      @for (column of columns; track column.headerName) {
        <ng-container matColumnDef="{{ column.headerName }}">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="text-xs font-medium text-grey"
          >
            {{ column.headerName }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="text-xs font-normal text-grey"
          >
            @if (column.field) {
              {{ element[column.field] }}
            } @else if (column.renderComponent) {
              <ng-container
                [ngTemplateOutlet]="column.renderComponent"
                [ngTemplateOutletContext]="{ $implicit: element }"
              ></ng-container>
            }
          </td>
        </ng-container>
      }

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns; sticky: true"
        [ngStyle]="{ 'background-color': '#FFFFFF', height: '40px' }"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [ngStyle]="{ 'background-color': i % 2 ? '#FFFFFF' : '#F7F8F9' }"
      ></tr>
    </table>
  </div>
</div>
