import { AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SepTableComponent } from '../../../../../shared/components/sep-table/sep-table.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { TextButtonComponent } from '../../../../../shared/components/text-button/text-button.component';
import { catchError, EMPTY, map, Observable } from 'rxjs';
import { OrganizationDTO } from '../../models/OrganizationDTO';
import { OrganizationService } from '../../services/organization.service';
import { AsyncPipe } from '@angular/common';
import { SnackbarService } from '../../../../../core/services/snackbar.service';
import { SimpleTableComponent } from '../../../../../shared/components/simple-table/simple-table.component';
import { SepAdminService } from '../../../services/sep-admin.service';
import { Column } from '../../../../../shared/components/sep-table/Column';
import { CustomPortalError } from '../../../../../core/errors/models/CustomPortalError';

@Component({
  selector: 'app-organizations-table',
  standalone: true,
  imports: [SepTableComponent, TextButtonComponent, AsyncPipe, TranslocoDirective, SimpleTableComponent],
  templateUrl: './organizations-table.component.html',
})
export class OrganizationsTableComponent implements OnInit, AfterViewInit {
  @ViewChild('organization', { static: true }) organization!: TemplateRef<{ $implicit: string }>;

  columns: Array<Column<string | { $implicit: string }>> = [{ headerName: 'Organization', renderComponent: undefined }];
  organizations$: Observable<OrganizationDTO[]>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly organizationService: OrganizationService,
    private readonly snackbarService: SnackbarService,
    private readonly sepAdminService: SepAdminService,
  ) {}

  ngOnInit() {
    this.organizations$ = this.organizationService.getOrganizations().pipe(
      map((response) => response.organizations),
      catchError((error: CustomPortalError) => {
        this.snackbarService.error(error.errorType);
        return EMPTY;
      }),
    );
  }

  ngAfterViewInit(): void {
    this.columns[0].renderComponent = this.organization;
    this.cdr.detectChanges();
  }

  handleTakeOverButtonClick(organization: OrganizationDTO): void {
    this.sepAdminService.startImpersonation(organization);
  }
}
