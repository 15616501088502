import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { CourseEnrollmentUserCreationDialogContainerComponent } from '../course-enrollment-user-creation-dialog-container/course-enrollment-user-creation-dialog-container.component';

@Component({
  selector: 'app-route-aware-portal-dialog',
  standalone: true,
  imports: [RouterModule, CommonModule, MatDialogModule],
  template: '',
})
export class RouteAwarePortalDialogComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.openDialog();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CourseEnrollmentUserCreationDialogContainerComponent, {
      autoFocus: false,
      width: '700px',
    });

    dialogRef.afterClosed().subscribe(() => {
      void this.router.navigate(['../'], { relativeTo: this.route });
    });
  }
}
