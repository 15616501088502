import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { SepTableSelectionService } from './sep-table-selection.service';

@Injectable({
  providedIn: 'root',
})
export class SepTableService<T> {
  dataSource: T[] = [];
  selection: SelectionModel<T> = new SelectionModel<T>(true, []);
  dataView: T[] = [];
  constructor(private readonly selectionService: SepTableSelectionService<T>) {}

  public setDataSource(dataSource: T[]) {
    this.dataSource = dataSource;
    this.dataView = dataSource;
    this.selectionService.clearSelection();
  }

  toggleAllRows() {
    this.selectionService.toggleAllRows(this.dataSource);
  }

  selectRow(row: T) {
    this.selectionService.selectRow(row);
  }

  get selection$() {
    return this.selectionService.selection$;
  }
}
